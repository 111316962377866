<template>
  <my-exam-results-component></my-exam-results-component>
</template>

<script>
  import MyExamResultsComponent from "./MyExamResultsComponent";
  export default {
    data() {
      return {}
    },
    components: {
      MyExamResultsComponent
    }
  };

</script>

<style scoped>
</style>
