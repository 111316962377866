
<template>
  <v-card>
    <!-- HEADER TOOLBAR -->
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{card_header_props.header.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{card_header_props.header.headLine}}</v-list-item-title>
          <v-list-item-subtitle>{{card_header_props.header.subTitle}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="card_header_props.helper.page_helper = !card_header_props.helper.page_helper"
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>

      <v-dialog v-model="card_header_props.helper.page_helper" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>{{$t("_common.How_can_I_use_this_form")}}</v-card-title>

          <v-card-text>{{$t("_exam_results._info.content")}}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="card_header_props.helper.page_helper = false">{{$t("_common.OK")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <!-- HEADER TOOLBAR -->
    <div>
      <v-row>
        <v-col cols="6">
          
          
          <v-autocomplete
            solo
            :items="sheets"
            :label='this.$t("_exam_results.Exam_filter")'
            item-text="Exam.Title"
            item-key="Exam.Id"
            v-model="selectedExam"
            @input="getAnswers"
            return-object
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :items="exams"
            item-key="ExamId"
            show-expand
            class="elevation-1"
            :headers="headers"
            :server-items-length="totalItems"
            :options.sync="options"
            :page.sync="options.page"
            :items-per-page.sync="options.itemsPerPage"
            :loading="tableLoading"
            @update:page="updatePage"
            @update:items-per-page="updatePage"
          >
            <template 
              v-slot:item.FinishDate="{ item }"
            >{{ fancyTimeString(new Date(item.FinishDate)) }}</template>
            <template v-slot:item.Score="{ item }">
              <v-chip small :color="successColor(item)" dark>{{ item.Score }} / {{sumTotalPoint(item.Questions)}}</v-chip>
            </template>
            <template v-slot:expanded-item="{ item, headers }" >
              <td :colspan="headers.length + 1" style="padding: 0 0 0 0">
                <v-card>
                  <div v-if="!item.IsAnswersPublished" class="p-4 text-center" style="background-color:#ededed;">
                    <strong>Soruların yanıtları henüz paylaşılmamıştır.</strong>
                  </div>
                  <v-list v-else-if="item.IsAnswersPublished">
                    <v-list-item
                      v-for="(question, q) in item.Questions"
                      :key="`exam${item.ExamId}-question${q}`"
                    >
                      <v-list-item-avatar>
                        <v-chip  small :color="choiceColor(question)" dark>{{q}}</v-chip>
                      </v-list-item-avatar>
                      <v-list-item-content v-html="question.Question.Text"></v-list-item-content>
                      <strong v-if="question.Question.Type != 3 && question.Question.Type != 4">
                        <v-list-item-content>
                          <v-chip outlined label small :color="choiceColor(question)" dark>{{$t("_exam_results.Point")}} : {{question.Question.Point}}</v-chip>
                        </v-list-item-content>
                      </strong>
                      <strong v-else-if="question.Question.Type == 3 || question.Question.Type == 4">
                        <v-list-item-content>
                          <v-chip outlined label small :color="choiceColorByPercent(question.Question.Point,question.Score)" dark>{{$t("_exam_results.Point")}} : {{question.Score}} / {{question.Question.Point}}</v-chip>
                        </v-list-item-content>
                      </strong>
                      <v-list-item-action v-if="question.Question.Type == 3">
                        <v-btn icon @click="openScoreAnswers(question)">
                          <v-icon
                            color="green lighten-1"
                          >mdi-information</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action v-if="question.Question.Type != 3">
                        <v-btn icon @click="openDialog(question)">
                          <v-icon
                            color="grey lighten-1"
                          >mdi-information</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
                <v-dialog v-model="activePrompt" max-width="600">
                  <v-card v-if="selectedQuestion != null">
                    <blockquote class="blockquote" v-html="selectedQuestion.Question.Text"></blockquote>

                    <v-card-text>
                      <v-list v-if="selectedQuestion.Question.Type == $goc.ENUMS.QUESTION_TYPE.MultipleChoice || selectedQuestion.Question.Type == $goc.ENUMS.QUESTION_TYPE.TrueFalse">
                        <v-list-item
                          v-for="(choice,c) in selectedQuestion.Question.Choices"
                          :key="`question${selectedQuestion.Question.Id}-choice${c}`"
                        >
                          <v-list-item-icon>
                            <v-icon
                              :color="getColor(choice.Correct)"
                            >{{getChoiceIcon(selectedQuestion, choice)}}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle v-text="choice.Text"></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-list v-else-if="selectedQuestion.Question.Type == $goc.ENUMS.QUESTION_TYPE.Matching">
                        <blockquote class="blockquote">{{$t("_exam_results.Correct_Answers_For_Question")}}</blockquote>
                        <v-list-item
                          v-for="(choice,c) in selectedQuestion.Question.MatchingChoices"
                          :key="`question${selectedQuestion.Question.Id}-matchingchoice${c}`"
                          v-if="choice.Key != '' && choice.Value != ''"
                        >
                          <v-list-item-icon>
                            <v-icon
                              color="green"
                            >mdi-check</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle v-text="choice.Key"></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-subtitle v-text="choice.Value"></v-list-item-subtitle>
                          </v-list-item-content>

                        </v-list-item>

                        <blockquote class="blockquote">{{$t("_exam_results.User_Answers_For_Question")}}</blockquote>
                        <v-list-item
                          v-for="(choice,c) in selectedQuestion.MatchingChoices"
                          :key="`question${selectedQuestion.Question.Id}-matchingchoice${c}`"
                        >
                          <v-list-item-icon>
                            <v-icon
                              :color="getColor(choice.Correct)"
                            >{{getChoiceIcon(selectedQuestion, choice)}}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-subtitle v-text="choice.Key"></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-subtitle v-text="choice.Value"></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <!--" fancyTime(selectedQuestion.AnswerTime ? selectedQuestion.AnswerTime : 0)-->
                            <v-list-item-subtitle
                              v-text="`Time spent: ${selectedQuestion.AnswerTime}` "
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn color="primary" text @click="activePrompt = false">{{$t("_common.Close")}} </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="AnswerForm.activeScoreAnswers" max-width="800">
                  <v-card v-if="selectedQuestion != null">


                    <template>
                      <v-card>
                        <!-- HEADER TOOLBAR -->
                        <v-toolbar class="page-primary-card-header">
                          <v-list-item dark>
                            <v-list-item-avatar color="primary lighten-2">
                              <v-icon>{{headers_open_ended_dialog.header.icon}}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="headline">{{headers_open_ended_dialog.header.headLine}}</v-list-item-title>
                              <v-list-item-subtitle>{{headers_open_ended_dialog.header.subTitle}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-btn
                            @click="headers_open_ended_dialog.helper.page_helper = !headers_open_ended_dialog.helper.page_helper"
                            icon
                            dark
                          >
                            <v-icon>info</v-icon>
                          </v-btn>

                          <v-dialog v-model="headers_open_ended_dialog.helper.page_helper" width="500">
                            <v-card>
                              <v-card-title class="headline grey lighten-2" primary-title>{{$t("_common.How_can_I_use_this_form")}}
                              </v-card-title>

                              <v-card-text>{{$t("_exam_results._info.content")}}</v-card-text>

                              <v-divider></v-divider>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="headers_open_ended_dialog.helper.page_helper = false">{{$t("_common.OK")}}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                        <!-- HEADER TOOLBAR -->
                        <div>
                          <v-row>
                            <blockquote class="blockquote">
                              <div v-html="AnswerForm.QuestionText"/>
                            </blockquote>
                          </v-row>
                          <v-row>
                            <div>
                              <p class="text-justify mx-12" v-html="AnswerForm.Text"></p>
                            </div>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <p class="text-right text--secondary">Time spent: {{AnswerForm.Time}}</p>
                            </v-col>
                          </v-row>
                        </div>
                        <v-footer>
                          <v-spacer></v-spacer>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn color="primary" text @click="AnswerForm.activeScoreAnswers = false">{{$t("_common.Close")}}</v-btn>
                            </template>
                            <span>{{$t("_score_answer.Score_Tooltip")}}</span>
                          </v-tooltip>
                        </v-footer>
                      </v-card>
                    </template>

                  </v-card>
                </v-dialog>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
  // import request from "./common/HttpRequests.js";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import ScoreAnswerComponent from "./ScoreAnswer";
  require("../../assets/css/neo.css");

  export default {
    data() {

      return {
        sheets: [],
        exams: [],
        expanded: [],
        options: {
          pagination: {
            page: 1, // skip
            itemsPerPage: 10, //take
            pageCount: 0,
          }
        },
        tableLoading: false,
        totalItems: 0,
        singleExpand: true,
        activePrompt: false,
        dialogComponentLoaded: false,
        headers_open_ended_dialog: {
          header: {
            headLine: this.$t('_score_answer.Score_Answers'),
            subTitle: this.$t('_score_answer.Score_Answers_Text'),
            icon: "mdi-format-align-left"
          },
          helper: {
            absolute: true,
            opacity: 0.8,
            overlay: false,
            page_helper: false
          }
        },
        headers: [
          {
            text: this.$t('_exam_results.Exam_Name'), //this.$t('_exam_results.Exam_Name'),
            align: "left",
            value: "ExamName",
          },
          {
            text: this.$t('_exam_results.User'), value: "UserName"
          },
          {
            text: this.$t('_exam_results.Date'), value: "FinishDate"
          },
          {
            text: this.$t('_exam_results.Score'), value: "Score"
          },
          {
            text: "", value: "data-table-expand"
          }
        ],
        item: 1,
        dialogChoices: false,
        examDataModel: [],
        selectedExam: [],
        selectedUser: [],
        selectedQuestion: null,
        pageVisible: false,
        AnswerForm: {
          activeScoreAnswers: false,
          QuestionText: "",
          Id: 1,
          Text: "",
          Time: "",
          Score: 0,
          FullScore: 50,
        },
        settings: {
          maxScrollbarLength: 80,
          wheelSpeed: 0.2
        },
        card_header_props: {
          header: {
            headLine: this.$t('_exam_results.Exam_Results'),
            subTitle: this.$t('_exam_results.Exam_Results_Text'),
            icon: "mdi-format-align-left"
          },
          helper: {
            absolute: true,
            opacity: 0.8,
            overlay: false,
            page_helper: false
          }
        }
      };
    },
    methods: {
      scoreAnswer() {
        const _self = this;
        this.$goc.request.post(
          this.$goc.ENUMS.API.Score_Answer,
          {
            Answers: [
              {
                AnswerId: this.AnswerForm.Id,
                Score: this.AnswerForm.Score
              },
            ]
          },
          () => {
            this.$goc.notify.success({
              title: this.$t('_common.Success'), message: this.$t('_score_answer.Success_Message')
            })
            this.getAnswers();
            _self.AnswerForm.activeScoreAnswers = false;
          }
        )
      },

      getExams() {

        let _self = this;
        let uid = JSON.parse(localStorage.getItem("userInfo")).Uid;
        let groupObj = {};
        this.$goc.request.get(this.$enums.API.MyExams + `${uid}`, response => {
          this.sheets = response.Result.Exams;
        });
      },
      getAnswers() {
        const _self = this;
        // var user = this.selectedUser;
        var sheet = this.selectedExam.Exam;
        let user = JSON.parse(localStorage.getItem("userInfo")).Uid;
        if (sheet === undefined || sheet.Id === undefined) sheet = 0;
        else sheet = this.selectedExam.Exam.Id;

        var query = "?";
        query = query + `user=${user}&`;
        query = query + `sheet=${sheet}&`;
        query = query + `skip=${(_self.options.page - 1) * _self.options.itemsPerPage}&take=${_self.options.itemsPerPage}`;

        const [sortBy] = _self.options.sortBy;
        const [sortDesc] = _self.options.sortDesc;
        if (sortBy) {
          query += `&sortBy=${sortBy}&sortDesc=${sortDesc}`;
        }
        else{
          query += `&sortBy=${null}&sortDesc=${false}`;
        }

        this.tableLoading = true;
        console.log(_self.options)
        this.$goc.request.get(this.$enums.API.Exam_Results.MyResult + query, {
          then: (response) => {
            this.totalItems = response.Result.TotalItemCount;
            this.exams = response.Result.Exams;
            this.$goc.console.log(this.exams)
            this.$goc.console.log(response.Result);
          },
          final: () => {
            this.tableLoading = false;
          }
        });
      },
      getChoiceIcon(question, choice) {
        let answered = question.AnswerChoice;
        if (choice.Id === answered) return "mdi-checkbox-blank-circle";
        else return "mdi-checkbox-blank-circle-outline";
      },
      getColor(item) {
        return item ? "green" : "red";
      },
      dialogNotify(message, notifyColor) {
        this.$vs.notify({
          title: "Error",
          text: message,
          color: notifyColor
        });
      },
      fancyTimeFormat(answerTime) {
        // Hours, minutes and seconds
        if (typeof answerTime !== undefined) {
          let timeLeft = answerTime;
          let hrs = ~~(timeLeft / 3600);
          let mins = ~~((timeLeft % 3600) / 60);
          let secs = ~~timeLeft % 60;

          // Output like "1:01" or "4:03:59" or "123:03:59"
          let ret = "";

          if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
          }

          ret += "" + mins + ":" + (secs < 10 ? "0" : "");
          ret += "" + secs;
          return ret;
        } else return "0 seconds";
      },
      fancyTimeString(date) {
        this.$goc.console.log(date)
        this.$goc.console.log(date.toLocaleString())
        return date.toLocaleString('en-GB');
      },
      choiceIcon(item) {
        let choiceChar = String.fromCharCode("1".charCodeAt(0) + item);
        return `mdi-numeric-${choiceChar}-circle-outline`;
      },
      choiceColor(item) {
        let chosen = item.AnswerChoice;
        for (var i = 0; i < item.Question.Choices.length; i++) {
          if (item.Question.Choices[i].Id === chosen) {
            if (item.Question.Choices[i].Correct) return "green";
            else return "red";
          }
        }
      },
      choiceColorByPercent(maxScore,score) {
        if(maxScore == score)
          return "green";
        else if(score == 0)
          return "red";
        else
          return "yellow"
      },
      successColor(item){
        let totalPoint = this.sumTotalPoint(item.Questions);
        if(item.SuccessRate == 0)
          item.SuccessRate = 60;
        var successPoint = totalPoint * item.SuccessRate / 100; 
        if(item.Score >= successPoint)
          return "green";
        else
          return "red";
      },
      fancyTime(time) {
        let hrs = ~~(time / 3600);
        let mins = ~~((time % 3600) / 60);
        let secs = ~~time % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        if (hrs > 0) {
          ret += `${hrs} hour`;
          if (hrs > 1) ret += "s";
        }
        if (mins > 0) {
          ret += ` ${mins} minute`;
          if (mins > 1) ret += "s";
        }
        if (secs > 0) {
          ret += ` ${secs} second`;
          if (secs > 1) ret += "s";
        }
        //
        // ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        // ret += "" + secs;
        return ret;
      },
      openDialog(question) {
        const _self = this;
        _self.activePrompt = true;
        _self.selectedQuestion = question;
        _self.dialogComponentLoaded = true;
      },
      openScoreAnswers(question) {
        const _self = this;
        _self.AnswerForm.activeScoreAnswers = true;
        _self.selectedQuestion = question;
        _self.AnswerForm.Id = question.Id;
        _self.AnswerForm.QuestionText = question.Question.Text;
        _self.AnswerForm.Text = question.Text;
        _self.AnswerForm.FullScore = question.Question.Point;
        _self.AnswerForm.Time = question.AnswerTime;
        _self.AnswerForm.Score = question.Score;
        _self.AnswerForm.OldScore = question.Score;

      },
      updatePage(page) {
        this.$goc.console.log(page)
        this.getAnswers();
      },
      sumTotalPoint(questions)
      {
        let totalPoint = 0;
        for (var i = 0; i < questions.length; i++) {
          totalPoint += questions[i].Question.Point;
        }

        return totalPoint;
      }

    },
    watch: {
      "AnswerForm.OldScore"(val) {
        console.log(val);

      },
      "AnswerForm.Score"(val) {
        console.log(val);
      },
      options() {
        this.getAnswers();
      }
    },
    mounted() {
      this.getExams();
    },
    components: {
      VuePerfectScrollbar,
      ScoreAnswerComponent
    }
  };

</script>
