<template>
  <v-card>
    <!-- HEADER TOOLBAR -->
    <v-toolbar class="page-primary-card-header">
      <v-list-item dark>
        <v-list-item-avatar color="primary lighten-2">
          <v-icon>{{card_header_props.header.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{card_header_props.header.headLine}}</v-list-item-title>
          <v-list-item-subtitle>{{card_header_props.header.subTitle}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-btn
        @click="card_header_props.helper.page_helper = !card_header_props.helper.page_helper"
        icon
        dark
      >
        <v-icon>info</v-icon>
      </v-btn>

      <v-dialog v-model="card_header_props.helper.page_helper" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>{{$t("_common.How_can_I_use_this_form")}}
          </v-card-title>

          <v-card-text>{{$t("_exam_results._info.content")}}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="card_header_props.helper.page_helper = false">{{$t("_common.OK")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <!-- HEADER TOOLBAR -->
    <div>
      <v-row>
        <blockquote class="blockquote">
          <div v-html="AnswerForm.QuestionText"/>
        </blockquote>
      </v-row>
      <v-row>
        <div>
          <p class="text-justify mx-12" v-html="AnswerForm.Text"></p>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="text-right text--secondary">Time spent: {{AnswerForm.Time}}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-slider
            inverse-label
            :label="this.$t('_score_answer.Score')"
            :thumb-size="24"
            thumb-label
            :min="0"
            :max="AnswerForm.FullScore"
            v-model="AnswerForm.Score"
          ></v-slider>
        </v-col>
      </v-row>
    </div>
    <v-footer>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click="scoreAnswer" v-on="on" type="button" color="success">{{$t("_common.Save")}}</v-btn>
          <v-btn color="primary" text @click="{{AnswerForm.activeScoreAnswers = false}}">{{$t("_common.Close")}} </v-btn>
        </template>
        <span>{{$t("_score_answer.Score_Tooltip")}}</span>
      </v-tooltip>
    </v-footer>
  </v-card>
</template>

<script>
  require("../../assets/css/neo.css");
  export default {
    data() {
      return {
        card_header_props: {
          header: {
            headLine: this.$t('_score_answer.Score_Answers'),
            subTitle: this.$t('_score_answer.Score_Answers_Text'),
            icon: "mdi-format-align-left"
          },
          helper: {
            absolute: true,
            opacity: 0.8,
            overlay: false,
            page_helper: false
          }
        },
        AnswerForm: {
          QuestionText: "",
          Id: 1,
          Text: "",
          Time: "",
          Score: 0,
          FullScore: 0,
        }
      }
    },
    methods: {
      init() {
        this.AnswerForm.QuestionText = this.QuestionText;
        this.AnswerForm.Id = this.Id;
        this.AnswerForm.Text = this.Text;
        this.AnswerForm.Time = this.Time;
        this.AnswerForm.Score = this.Score;
        this.AnswerForm.FullScore = this.FullScore;
      },
      scoreAnswer() {
        const _self = this;
        this.$goc.request.post(
          this.$goc.ENUMS.API.Score_Answer,
          {
            Answers: [
              {
              AnswerId: this.AnswerForm.Id,
              Score: this.AnswerForm.Score
            },
            ]
          },
          () => {
            this.$goc.notify.success({
              title: this.$t('_common.Success'), message: this.$t('_score_answer.Success_Message')
            })
            _self.activeScoreAnswers = false;
            _self.$emit('update:AnswerForm.activeScoreAnswers', activeScoreAnswers)
          }
        )
      },
    },
    mounted() {
      this.init();
    },

    props: {
      QuestionText: String,
      Id: Number,
      Text: String,
      Time: String,
      Score: Number,
      FullScore: Number,
      activeScoreAnswers: Boolean,
    },
    name: "ScoreAnswer"
  }
</script>

<style scoped>

</style>
